<template>
  <div class="manage_layer_03">
    <div class="layer_top">
      <h2 class="layer_top_title">문제신고 관리</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_03">
      <form>
        <div class="mb_30" v-if="showType == 'edit'">
          <base-select
            :options="statusOptions"
            v-model="selectedStatus"
            placeholder="선택"
          />
        </div>
        <div class="mb_30" v-else>
          <span style="font-weight: bold">진행 상태:</span> {{ confirmStatus }}
        </div>
        <table>
          <colgroup>
            <col style="width: 184px" />
            <col style="width: 244px" />
            <col style="width: 184px" />
            <col style="width: 244px" />
            <col style="width: 184px" />
            <col style="width: 214px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">작성자 명</td>
            <td class="box_style_02">
              <span>{{ user.name }}</span>
            </td>
            <td class="box_style_02 list_title_style">아이디</td>
            <td class="box_style_02">
              <span>{{ user.loginid }} </span>
            </td>
            <td class="box_style list_title_style">이메일 주소</td>
            <td class="box_style">
              <span>{{ user.email }} </span>
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">구분</td>
            <td class="box_style_02">{{ type }}</td>
            <td class="box_style_02 list_title_style">작성 일시</td>
            <td class="box_style_02" colspan="3">
              {{ registerDate }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">내용</td>
            <td class="box_style_02" colspan="5" style="width: 1070px">
              {{ content }}
            </td>
          </tr>
          <tr class="line_style" v-if="showType == 'edit'">
            <td class="box_style_02 list_title_style">답변</td>
            <td class="box_style_03" colspan="5" style="width: 1070px">
              <textarea
                v-model="reply"
                style="width: 100%; min-height: 100px; resize: none"
                placeholder="답변을 입력해 주세요."
              ></textarea>
            </td>
          </tr>
          <tr class="line_style" v-else>
            <td class="box_style_02 list_title_style">답변</td>
            <td class="box_style_03" colspan="5">
              {{ reply }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit" v-if="showType == 'edit'"
            >답변하기</base-button
          >
          <base-button type="primary-lg" @click="$emit('close')" v-else
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BaseButton from "./core/BaseButton.vue";
import BaseSelect from "./core/BaseSelect.vue";
import Dialog from "@/components/Dialog.vue";
import client from "api-client";
export default {
  components: { BaseButton, BaseSelect },
  props: {
    id: Number,
    showType: String,
  },
  data() {
    return {
      user: {
        name: "",
        loginid: "",
        email: "",
      },
      type: "",
      registerDate: "",
      content: "",
      reply: "",
      statusOptions: [
        { name: "접수", value: "1" },
        { name: "처리중", value: "2" },
        { name: "완료", value: "3" },
      ],
      selectedStatus: "",
      confirmStatus: "",
      confirm: {
        status: "",
        reply: "",
      },
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async edit() {
      let params = {
        reply: this.reply,
        status: this.selectedStatus,
        type: this.type ? this.type : "-",
        content: this.content,
      };
      await client.reportModify(this.id, params).then(
        (response) => {
          console.log(response);
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "답변이 정상적으로 수정되었습니다.",
            },
            {
              width: 400,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          this.$emit("close", { refresh: true });
        },
        (error) => {
          const { data } = error.response;
          let message = data.message;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
      this.$emit("close");
    },
    async fetch() {
      await client.reportFindone(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.user.name = data.user ? data.user.name : "";
          this.user.loginid = data.user ? data.user.loginid : "";
          this.user.email = data.user ? data.user.email : "";
          this.type = data.type;
          this.registerDate = this.$d(new Date(data.registerDate), "long");
          this.content = data.content;
          this.reply = data.reply;
          this.selectedStatus = data.status;
          if (data.status == 1) {
            this.confirmStatus = "접수";
          } else if (data.status == 2) {
            this.confirmStatus = "처리중";
          } else {
            this.confirmStatus = "완료";
          }
          this.confirm.status = data.status;
          this.confirm.reply = data.reply;
        },
        (error) => {
          const { data } = error.response;
          let message = data.message;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
    },
  },
};
</script>
